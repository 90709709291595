import React, { Component, Fragment } from "react";
import ApiService from "../../services/ApiService";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

const PaginatedTable = ({ data }) => {
  return (
    <PaginationProvider pagination={paginationFactory(data.options)}>
      {({ paginationProps, paginationTableProps }) => (
        <>
          <div>
            <BootstrapTable
              rowStyle={data.hasDetailedView ? { cursor: "pointer" } : {}}
              data={data.tableRows}
              columns={data.columns}
              keyField={data.columns[0].dataField}
              classes="table-hover"
              loading={data.loading}
              remote
              sort={{
                dataField:
                  data.queryData.get("sortField") ||
                  data.columns[0]?.dataField ||
                  "",
                order: data.queryData.get("sortOrder") || "asc",
              }}
              onTableChange={(type, { sortField, sortOrder }) => {
                if (type === "sort" && data.onSortChange) {
                  data.onSortChange(sortField, sortOrder);
                }
              }}
              {...paginationTableProps}
              noDataIndication="La tabla está vacía"
              overlay={overlayFactory({
                spinner: true,
                styles: {
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(18,139,252,0.8)",
                  }),
                },
              })}
            />
          </div>
          <div className="float-left mt-5">
            <PaginationTotalStandalone {...paginationProps} />
          </div>
          <div className="float-right mt-5">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </>
      )}
    </PaginationProvider>
  );
};

class BasicTable extends Component {
  constructor(props) {
    super();
    this.history = props.history;
    this.state = {
      hasDetailedView: props.hdv,
      tableRows: [],
      columns: props.columns.filter((it) => it.isForTable),
      path: props.path,
      loading: true,
      queryData: new Map([
        ["sortField", ""],
        ["sortOrder", ""],
      ]),
      filterParams: null,
      checkedColumn: props.checkedColumn,
      currentPage: 1,
      options: null,
    };
    this.state.options = this.getTableOptions(
      1,
      10,
      this.changeFunction.bind(this)
    );
  }

  onSend = (filterParams) => {
    const filterMap = filterParams instanceof Map ? filterParams : new Map();
    this.setState({ filterParams: filterMap }, () => {
      this.loadTableData(1, true);
    });
  };

  componentDidMount() {
    this.loadTableData(1, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const sortChanged = !this.compareMaps(
      this.state.queryData,
      prevState.queryData
    );
    const filterChanged = !this.compareMaps(
      this.state.filterParams,
      prevState.filterParams,
      true
    );

    if (sortChanged || filterChanged) {
      this.loadTableData(1, true);
    }
  }

  buildRequest(page) {
    let request = `${this.state.path}?page=${page - 1}`;
    this.state.queryData.forEach((value, key) => {
      if (value) request += `&${key}=${value}`;
    });

    if (this.state.filterParams) {
      this.state.filterParams.forEach((value, key) => {
        if (value !== null && value !== "") {
          request += `&${key}=${encodeURIComponent(value)}`;
        }
      });
    }
    return request;
  }

  async loadTableData(page = 1, updateState = true) {
    let request = this.buildRequest(page);

    if (request.startsWith("/inspection") && !request.includes("sortField")) {
      this.setState({ lastRequest: request }, () => {
        this.fetchData(request, updateState, page);
      });
    } else if (request.includes("sortField") && this.state.lastRequest) {
      const newParams = request
        .split("?")[1]
        .split("&")
        .filter((param) => !param.startsWith("page="))
        .join("&");

      let updatedRequest = this.state.lastRequest.replace(
        /page=\d+/,
        `page=${page - 1}`
      );
      updatedRequest = `${updatedRequest}&${newParams}`;

      this.setState({ currentPage: page }, () =>
        this.fetchData(updatedRequest, updateState, page)
      );
    } else {
      this.setState({ currentPage: page }, () =>
        this.fetchData(request, updateState, page)
      );
    }
  }

  async fetchData(request, updateState, page) {
    if (updateState) this.setState({ loading: true });
    request = request
      .replace("inspector.dni=", "dni=")
      .replace("sortField=", "sort=")
      .replace("sortOrder=", "order=");

    try {
      const { data } = await ApiService.getAxios().get(request);

      const tableRows = data.content || [];
      const options = this.getTableOptions(
        page,
        data.totalElements,
        this.changeFunction.bind(this)
      );

      this.setState({ tableRows, options, loading: false });
    } catch (error) {
      console.error("Error fetching table data:", error);
      if (updateState) this.setState({ loading: false });
    }
  }

  compareMaps(map1, map2, allowNull = false) {
    if (allowNull) {
      if (map1 === map2) return true;
      if (!map1 || !map2) return false;
    }

    if (!map1 || !map2 || !(map1 instanceof Map) || !(map2 instanceof Map)) {
      return false;
    }

    if (map1.size !== map2.size) return false;

    for (let [key, val] of map1) {
      if (val !== map2.get(key)) return false;
    }

    return true;
  }

  changeFunction = (page) => {
    this.loadTableData(page, true);
  };

  onSortChange = (sortField, sortOrder) => {
    this.setState(
      (prevState) => {
        const newQueryData = new Map(prevState.queryData);
        newQueryData.set("sortField", sortField);
        newQueryData.set("sortOrder", sortOrder);
        return { queryData: newQueryData };
      },
      () => {
        this.loadTableData(this.state.currentPage, true);
      }
    );
  };

  getTableOptions(page, totalItems, onChangeFunc) {
    return {
      pageStartIndex: 1,
      page: this.state.currentPage,
      sizePerPage: 10,
      totalSize: totalItems,

      onPageChange: (newPage) => {
        this.setState({ currentPage: newPage });
        onChangeFunc(newPage);
      },
      alwaysShowAllBtns: true,
      custom: true,

      paginationTotalRenderer: (from, to, size) =>
        size > 0 ? (
          <span className="react-bootstrap-table-pagination-total">
            Mostrando {to - from + 1} resultados de un total de {size}
          </span>
        ) : (
          <> </>
        ),
    };
  }

  updateOrder = (data) => {
    this.setState({ sortedData: data });
  };

  render() {
    return (
      <Fragment>
        <div className="table-responsive">
          <PaginatedTable
            data={{
              ...this.state,
              onSortChange: this.onSortChange,
            }}
          />
        </div>
      </Fragment>
    );
  }
}

export default BasicTable;
