import React, { Component } from "react";
import { Form } from "react-bootstrap";

class InputNumberBasic extends Component {
  constructor(props) {
    super(props);
    this.idx = props.idx;
    this.element = props.element;
    this.handleChange = props.handleChange;
  }

  render() {
    return (
      <>
        <style>
          {`
            /* Chrome, Safari, Edge, Opera */
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            input[type="number"] {
              -moz-appearance: textfield;
            }
          `}
        </style>
        <label htmlFor={this.idx}>
          {this.element.detailedText != null
            ? this.element.detailedText
            : this.element.text}
        </label>
        <Form.Control
          onChange={(e) => {
            // Only allow numbers
            if (e.target.value === "" || /^[0-9]+$/.test(e.target.value)) {
              this.handleChange(e);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
              e.preventDefault();
            }
          }}
          onBlur={this.handleChange}
          className="form-control"
          name={this.element.dataField}
          id={this.idx === 0 ? "0" : this.idx}
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          required={this.element.required}
          min={this.element.min ? this.element.min : 0}
          max={this.element.max ? this.element.max : 9999999999}
          value={this.props.value !== undefined ? this.props.value : ""}
        />
        {this.element.hasError && (
          <span className="text-danger">{this.element.messageError}</span>
        )}
      </>
    );
  }
}

export default InputNumberBasic;
