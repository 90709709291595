import axios from "axios";
import UserProfile from "../app/shared/UserProfile";

class ApiService {
  static instance = null;
  userProfile = null;
  _axios = null;

  static getInstance() {
    if (ApiService.instance == null) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  getUserProfile() {
    if (this.userProfile == null) {
      this.userProfile = UserProfile.getInstance();
    }
    return this.userProfile;
  }

  getAxiosClient() {
    if (this._axios == null) {
      this._axios = axios.create();

      this._axios.interceptors.request.use((config) => {
        const token = UserProfile.getInstance().getToken();
        // console.log(token);
        config.headers.Authorization = `Bearer ${token}`;
        axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
        config.baseURL = window.globalConfig.apiUrl;
        config.timeout = window.globalConfig.apiTimeout;
        return config;
      });
    }
    return this._axios;
  }
}

const post = (path, data, doCallback) => {
  return ApiService.getInstance()
    .getAxiosClient()
    .post(path, data)
    .then((response) => {
      if (doCallback) {
        return doCallback(response);
      }
      return response;
    })
    .catch(function (e) {});
};

const get = (path, doCallback) => {
  return ApiService.getInstance().getAxiosClient().get(path);
};

const getAxios = () => {
  return ApiService.getInstance().getAxiosClient();
};

const services = {
  get,
  post,
  getAxios,
};

export default services;
