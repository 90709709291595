import React, { Component, Fragment } from "react";
import BasicTable from "../../app/tables/BasicTable";
import Filter from "../../app/utils/Filter";
import BigCard from "../../app/utils/BigCard.jsx";
import { Link } from "react-router-dom";

class Table extends Component {
  constructor(props) {
    super(props);
    this.hasDetailedView = props.hasDetailedView ?? true;
    this.friendlyPath = props.friendlyPath;
    this.columns = props.columns;
    this.path = props.path;
    this.isOrderAsc = true;
    this.history = props.history;
    this.showTableInit = props.showTableInit ?? true;
    this.table = React.createRef();
    this.tableUpdateOrder = this.tableUpdateOrder.bind(this);
  }

  handleHeaderClick = (column) => {
    this.columns.forEach((c) => (c.headerClasses = null));
    column.headerClasses = () =>
      `pointer mdi mdi-arrow-${
        this.isOrderAsc ? "up" : "down"
      }-drop-circle-outline`;
    this.isOrderAsc = !this.isOrderAsc;

    const orderData = new Map()
      .set("sort", column.dataField)
      .set("order", this.isOrderAsc ? "asc" : "desc");
    this.tableUpdateOrder(orderData);
  };

  tableUpdateOrder = (data) => {
    if (this.table.current?.onSend) {
      this.table.current.onSend(data);
    } else {
      console.error("updateOrder is not defined on BasicTable");
    }
  };

  render() {
    const filters = [];
    const tableColumns = [];

    this.columns.forEach((c) => {
      c.headerStyle = { cursor: "pointer" };
      c.headerEvents = { onClick: (_, b) => this.handleHeaderClick(b) };

      if (this.hasDetailedView) {
        c.events = {
          onClick: (_, column, columnIndex, row) =>
            this.history.push(
              `/${this.friendlyPath}/informacion-detallada/${row.id}`
            ),
        };
      }

      if (c.inputType === "checkbox") {
        c.formatter = (cell, row) => (row.enabled ? "Si" : "No");
      }

      const d = { ...c, headerEvents: {}, events: {}, formatter: {} };
      tableColumns.push(d);

      if (c.isFilter) {
        filters.push({
          label: c.text,
          dataField: c.dataField,
          inputType: c.inputType,
          path: c.dropdownPath,
        });
      }
    });

    const buttonStyle = { height: "2.8rem", marginTop: "2rem" };

    const dataCreateLink = {
      pathname: `/${this.props.friendlyPath}/crear`,
      state: { entity: tableColumns, path: this.path },
    };

    const createButtonText = (
      <>
        <i className="fa fa-plus mr-4" />
        Crear nuevo
      </>
    );

    return (
      <Fragment>
        {filters.length > 0 && (
          <BigCard>
            <Filter
              filters={filters}
              onSend={(filterData) => {
                this.tableUpdateOrder(filterData);
              }}
            >
              {this.props.hasCreate && (
                <Link
                  className="btn btn-outline-primary col col-lg-auto"
                  to={dataCreateLink}
                  style={{
                    ...buttonStyle,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {createButtonText}
                </Link>
              )}
              {this.props.hasDownload && (
                <a
                  href={`${window.globalConfig.apiUrl}${this.path}/export`}
                  download
                  className="col-lg-auto"
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary col"
                    style={buttonStyle}
                  >
                    <i className="fa fa-download"></i> Exportar
                  </button>
                </a>
              )}

              {this.props.hasDownload && filters.length < 1 && (
                <div className="mb-5 pb-4">
                  <a
                    href={`${window.globalConfig.apiUrl}${this.path}/export`}
                    download
                  >
                    <button
                      type="button"
                      className="btn btn-primary btn-lg float-right"
                    >
                      <i className="fa fa-download"></i> Exportar
                    </button>
                  </a>
                </div>
              )}

              {this.props.hasCreate && filters.length < 1 && (
                <div className="mb-5 pb-4">
                  <Link to={dataCreateLink}>
                    <button
                      type="button"
                      className="btn btn-primary btn-lg float-right"
                    >
                      {createButtonText}
                    </button>
                  </Link>
                </div>
              )}
            </Filter>
          </BigCard>
        )}

        <BigCard>
          <BasicTable
            history={this.history}
            columns={this.columns}
            path={this.path}
            ref={this.table}
            hdv={this.hasDetailedView}
          />
        </BigCard>
      </Fragment>
    );
  }
}

export default Table;
