import React, { Component, Fragment } from "react";
import BasicTable from "./BasicTable";
import Filter from "../utils/Filter";
import BigCard from "../utils/BigCard.jsx";
import { Link } from "react-router-dom";
import InspectionView from "../../components/info-views/InspectionView";

class InspectionTable extends Component {
  constructor(props) {
    super(props);
    this.hasDetailedView = props.hasDetailedView ?? true;
    this.friendlyPath = props.friendlyPath;
    this.columns = props.columns;
    this.path = props.path;
    this.exportPath = process.env.REACT_APP_REACT_APP_INSPECTION_EXPORT_CSV;
    this.isOrderAsc = true;
    this.history = props.history;
    this.showTableInit = props.showTableInit ?? true;
    this.hasDownload = props.hasDownload;
    this.table = React.createRef();
    this.tableUpdateOrder = this.tableUpdateOrder.bind(this);

    this.state = {
      showTable: true,
      forExport: {},
    };
  }

  inspectionViewRef = React.createRef();

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState !== this.state)
      if (this.state.showTable)
        window.history.pushState({}, null, `/inspecciones/`);
  };

  handleHeaderClick = (column) => {
    this.columns.forEach((c) => (c.headerClasses = null));
    column.headerClasses = () =>
      `pointer mdi mdi-arrow-${
        this.isOrderAsc ? "up" : "down"
      }-drop-circle-outline`;
    this.isOrderAsc = !this.isOrderAsc;

    const updatedData = new Map(this.state.queryData);
    updatedData.set("sortField", column.dataField);
    updatedData.set("sortOrder", this.isOrderAsc ? "asc" : "desc");

    this.tableUpdateOrder(updatedData);
  };

  tableUpdateOrder = (data) => {
    if (this.table.current?.onSend) {
      this.table.current.onSend(data);
    } else {
      console.error("updateOrder is not defined on BasicTable");
    }
  };

  toggleShowTable = () => {
    const showTable = this.state.showTable;
    this.setState({ showTable: !showTable });
  };

  buildRequest() {
    let request = "";

    for (const [key, value] of this.state.queryData) {
      request += `&${key}=${value}`;
    }

    return request;
  }

  render() {
    let filters = [];
    let tableColumns = [];

    this.columns.forEach((c) => {
      c.headerStyle = { cursor: "pointer" };
      c.headerEvents = { onClick: (a, b) => this.handleHeaderClick(b) };

      if (this.hasDetailedView) {
        c.events = {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.inspectionViewRef.current.setState({ id: row.id });
            this.forceUpdate();
            window.history.pushState(
              {},
              null,
              `/inspecciones/informacion-detallada/${row.id}`
            );
            this.toggleShowTable();
          },
        };
      }

      if (c.inputType === "checkbox") {
        c.formatter = function (cell, row, rowIndex, formatExtraData) {
          return row.enabled ? "Si" : "No";
        };
      }

      let d = Object.assign({}, c);
      d.headerEvents = {};
      d.events = {};
      d.formatter = {};
      tableColumns.push(d);

      if (c.isFilter) {
        filters.push({
          label: c.text,
          dataField: c.dataField === "inspector.dni" ? "dni" : c.dataField,
          inputType: c.inputType,
          path: c.dropdownPath,
        });
      }
    });

    const buttonStyle = {
      height: "2.8rem",
      marginTop: "2rem",
    };

    const dataCreateLink = {
      pathname: `/${this.props.friendlyPath}/crear`,
      state: {
        entity: tableColumns,
        path: this.path,
      },
    };

    const createButtonText = (
      <Fragment>
        <i className="fa fa-plus mr-4"></i> Crear nuevo
      </Fragment>
    );

    return (
      <Fragment>
        {
          <div className={!this.state.showTable ? "" : "hidden"}>
            <InspectionView
              id={-1}
              ref={this.inspectionViewRef}
            ></InspectionView>
          </div>
        }

        <div className={this.state.showTable ? "" : "hidden"}>
          {filters && filters.length > 0 && (
            <BigCard>
              <Filter
                filters={filters}
                onSend={(filterData) => {
                  this.tableUpdateOrder(filterData);
                }}
                errorMessage={"Debe colocar un DNI o fechas"}
                hasDownload={true}
                exportPath={this.exportPath}
              >
                {this.props.hasCreate && (
                  <Link to={dataCreateLink} className="col-lg-auto">
                    <button
                      className="btn btn-outline-primary col"
                      style={buttonStyle}
                    >
                      {createButtonText}
                    </button>
                  </Link>
                )}
              </Filter>
            </BigCard>
          )}

          {this.props.hasCreate && filters.length < 1 && (
            <div className="mb-5 pb-4">
              <button
                type="button"
                className="btn btn-primary btn-lg float-right"
              >
                <Link to={dataCreateLink}>{createButtonText}</Link>
              </button>
            </div>
          )}
          <BigCard>
            <BasicTable
              history={this.history}
              columns={this.columns}
              path={this.path}
              ref={this.table}
              hdv={this.hasDetailedView}
            />
          </BigCard>
        </div>
        {!this.state.showTable && (
          <button
            className={`btn btn-dark btn-lg float-left ml-3 ${
              this.props.isCreate ? "" : "mt-4"
            }`}
            onClick={this.toggleShowTable}
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Volver
          </button>
        )}
      </Fragment>
    );
  }
}
export default InspectionTable;
