import React, { Component } from "react";
import { Form } from "react-bootstrap";

class InputDateBasic extends Component {
  constructor(props) {
    super(props);
    this.idx = props.idx;
    this.element = props.element;
    this.handleChange = props.handleChange;

    // Set initial value if defaultValue exists
    if (this.element.defaultValue && !this.props.value) {
      this.handleChange({
        target: {
          name: this.element.dataField,
          value: this.element.defaultValue,
        },
      });
    }
  }

  render() {
    const today = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD

    return (
      <>
        <label htmlFor={this.idx}>
          {this.element.detailedText != null
            ? this.element.detailedText
            : this.element.text}
        </label>
        <Form.Control
          onChange={this.handleChange}
          onBlur={this.handleChange}
          className="form-control"
          name={this.element.dataField}
          id={this.idx === 0 ? "0" : this.idx}
          type="date"
          required={this.element.required}
          value={
            this.props.value ||
            (this.element.dataField === "toDate" ? today : "")
          }
          defaultValue={this.element.dataField === "toDate" ? today : ""}
        />
        {this.element.hasError && (
          <span className="text-danger">{this.element.messageError}</span>
        )}
      </>
    );
  }
}

export default InputDateBasic;
