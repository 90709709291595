import React, { Component } from "react";
import InspectionTable from "../../app/tables/InspectionTable.jsx";

class ReportByDateAndDni extends Component {
  constructor() {
    super();
    this.friendlyPath = "inspecciones";
    this.path = process.env.REACT_APP_REPORT_DATEANDDNI;

    this.state = {
      noLineMaster: false,
    };

    this.columns = [
      {
        dataField: "id",
        text: "Id",
        isForTable: true,
        isFilter: false,
        inputType: "number",
      },
      {
        dataField: "inspector.dni",
        text: "DNI",
        isForTable: true,
        isFilter: true,
        inputType: "number",
        min: 1,
        max: 999999999,
        name: "dni",
      },
      {
        dataField: "inspector.fullname",
        text: "Nombre",
        isForTable: true,
        isFilter: false,
        inputType: "number",
        min: 1,
        max: 999999999,
      },
      {
        dataField: "cardInfo.cardNumber",
        text: "Tarjeta",
        isForTable: true,
        inputType: "number",
        isFilter: false,
      },
      {
        dataField: "fromDate",
        text: "Fecha Desde",
        inputType: "date",
        isFilter: true,
        name: "fromDate",
      },
      {
        dataField: "toDate",
        text: "Fecha Hasta",
        inputType: "date",
        isFilter: true,
        name: "toDate",
        defaultValue: new Date().toISOString().split("T")[0],
      },
      {
        dataField: "noLineMaster",
        text: "Incluir inspecciones sin línea",
        inputType: "checkbox",
        isFilter: true,
        name: "noLineMaster",

        onChange: (event) => {
          this.setState({ noLineMaster: event.target.checked });
        },
      },
      {
        dataField: "inspectionDate",
        text: "Fecha inspección",
        isForTable: true,
        inputType: "text",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            row.inspectionDate.substring(0, 10) +
            " " +
            row.inspectionDate.substring(11, 19)
          );
        },
      },
      {
        dataField: "result",
        // dataField: "validationReason.description",
        text: "Resultado",
        isForTable: true,
        inputType: "text",
        isFilter: false,
      },
    ];
  }

  render = () => {
    return (
      <InspectionTable
        columns={this.columns}
        path={this.path}
        friendlyPath={this.friendlyPath}
        hasCreate={false}
        hasDownload={true}
        history={this.props.history}
        showTableInit={false}
      />
    );
  };
}
export default ReportByDateAndDni;
