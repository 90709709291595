import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import { BrowserRouter as Router } from "react-router-dom";
import UserService from "./services/UserService";

//configuracion
//const appConfig = window.globalConfig
const renderApp = () =>
  ReactDOM.render(
    <Router basename="/">
      <App />
    </Router>,
    document.getElementById("root")
  );
UserService.initKeycloak(renderApp);
