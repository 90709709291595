import React, { Component, Fragment } from "react";
import Select from "react-select";
import ApiService from "../../services/ApiService";

class Selector extends Component {
  constructor(props) {
    super(props);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.name = props.property;
    this.path = props.path;
    this.refer = props.refer;
    this.getValueForCreate = props.getValueForCreate;
    this.isForFilter = props.isForFilter;
    this.isForCreate = props.isForCreate;
    this.placeholder = props.placeholder;
    this.isMulti = props.isMulti;
    this.isClearable = true;
    this.isSearchable = true;
    this.escapeClearsValue = true;
    this.defaultValue = props.defaultValue;
    this.state = {
      options: [],
      value: this.defaultValue ? this.defaultValue : [],
    };
  }
  select = React.createRef();

  async componentDidMount() {
    let options = await this.getOptions();
    this.setState({ options });
  }

  async getOptions() {
    const axios = ApiService.getAxios();
    return await axios.get(this.path).then((res) => {
      if (res.status !== 200) return [];
      else {
        let options = res.data.map((item) => ({
          value: item.id,
          label: item.description,
        }));
        return options;
      }
    });
  }

  handleChangeSelect(e) {
    this.getValueForCreate(e, this.name, e?.length);
    try {
      this.setState({ value: e });
    } catch (e) {
      this.setState({ value: null });
    }
    this.forceUpdate();
  }

  render() {
    return (
      <Fragment>
        <Select
          name={this.name}
          onChange={this.handleChangeSelect}
          options={this.state.options}
          isMulti={this.isMulti ? "isMulti" : ""}
          isClearable={this.isClearable}
          isSearchable={this.isSearchable}
          escapeClearsValue={this.escapeClearsValue}
          placeholder={this.placeholder}
          defaultValue={this.defaultValue}
          ref={this.refer}
        ></Select>
      </Fragment>
    );
  }
}

export default Selector;
