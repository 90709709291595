import React, { Component } from "react";
import Selector from "../../app/basic-ui/Selector";
import InputNumberBasic from "../../components/inputs/InputNumberBasic.js";
import InputTextBasic from "../../components/inputs/InputTextBasic.js";
import InputCheckboxBasic from "../../components/inputs/InputCheckboxBasic.js";
import InputDropdownBasic from "../../components/inputs/InputDropdownBasic.js";
import InputDateBasic from "../../components/inputs/InputDateBasic";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import UserProfile from "../shared/UserProfile";
import axios from "axios";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.userProfile = UserProfile.getInstance();
    this.errorMessage = props.errorMessage;
    this.filters = props.filters;
    this.request = new Map();
    this.hasDownload = props.hasDownload;
    this.exportPath = props.exportPath;
    this.onSend = props.onSend;

    const filterValues = {};

    props.filters.forEach((filter) => {
      if (filter.defaultValue) {
        filterValues[filter.dataField] = filter.defaultValue;
      } else {
        filterValues[filter.dataField] =
          filter.inputType === "checkbox" ? false : "";
      }
    });
    this.state = {
      showMessage: false,
      exportPushed: false,
      exportPathValues: new Map(),
      filterValues: filterValues,
    };
  }

  handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    this.setState((prevState) => ({
      filterValues: {
        ...prevState.filterValues,
        [name]: newValue,
      },
    }));

    this.putValueOnRequestMap(name, newValue);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { exportPushed } = this.state;
    let values = this.pickUpValues(e);
    let oneInputFilled = values.some((value) => value);

    const dniInput = Array.from(e.target).find((input) => input.name === "dni");
    const fromDateInput = Array.from(e.target).find(
      (input) => input.name === "fromDate"
    );

    if (!dniInput?.value && !fromDateInput?.value) {
      this.setState({ showMessage: true });
      return;
    }

    if (e.target.reportValidity()) {
      this.request = new Map();

      Array.from(e.target).forEach((input) => {
        if (input.name) {
          const value = input.type === "checkbox" ? input.checked : input.value;
          if (value !== null && value !== "") {
            this.request.set(input.name, value);
          }
        }
      });

      if (exportPushed === true) {
        const query = this.generateRequestPath(this.request);

        const token = UserProfile.getInstance().getToken();
        const baseUrl = window.globalConfig.apiUrl;

        if (!this.exportPath) {
          console.error("Export - Error: No export path provided");
          alert("Error: No se ha configurado la ruta de exportación");
          this.setState({ exportPushed: false });
          return;
        }

        try {
          const response = await axios.get(query, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            baseURL: baseUrl,
          });

          if (response.data) {
            let csvContent = response.data
              .trim()
              .replace(/\r\n|\r|\n/g, "\n")
              .replace(/;+(\n|$)/g, ";$1");

            const lines = csvContent.split("\n");
            const headers = lines[0].split(";");

            if (!headers.length || !csvContent.includes(";")) {
              throw new Error("El contenido no parece ser un CSV válido");
            }

            const BOM = "\uFEFF";
            csvContent = BOM + csvContent;

            const blob = new Blob([csvContent], {
              type: "text/csv;charset=utf-8",
            });

            // Descarga el archivo
            saveAs(blob, this.generateFileName());
          } else {
            throw new Error("No content available in the response");
          }

          this.setState({ exportPushed: false });
        } catch (error) {
          console.error("Export - Error occurred:", {
            message: error.message,
            status: error.response?.status,
            statusText: error.response?.statusText,
            data: error.response?.data,
          });
          this.setState({ exportPushed: false });
          alert("Error al exportar los datos. Por favor, intente nuevamente.");
        }
      } else {
        this.onSend(this.request);
      }
    } else {
      this.setState({ showMessage: true });
      setTimeout(() => this.removeRequired(oneInputFilled, e.target), 5000);
    }
  };

  resetFilterValues = () => {
    this.setState({
      filterValues: this.filters.reduce((acc, filter) => {
        acc[filter.dataField] = filter.inputType === "checkbox" ? false : "";
        return acc;
      }, {}),
    });
  };

  generateFileName = () => {
    const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
    return (
      "Inspecciones " + new Date().toLocaleDateString("es-AR", options) + ".csv"
    );
  };

  generateRequestPath = (request) => {
    let query = this.exportPath;

    if (!query) {
      console.error("Export - Error: exportPath is undefined");
      return "";
    }

    const paramOrder = ["dni", "fromDate", "toDate", "noLineMaster"];
    let isFirstParam = true;

    paramOrder.forEach((param) => {
      if (
        request.has(param) &&
        request.get(param) !== null &&
        request.get(param) !== ""
      ) {
        query += isFirstParam ? "?" : "&";
        query += `${param}=${encodeURIComponent(request.get(param))}`;
        isFirstParam = false;
      }
    });

    for (let [key, value] of request) {
      if (!paramOrder.includes(key) && value !== null && value !== "") {
        query += isFirstParam ? "?" : "&";
        query += `${key}=${encodeURIComponent(value)}`;
        isFirstParam = false;
      }
    }

    return query;
  };

  pickUpValues = (e) => {
    this.setState({
      showMessage: false,
      hasBeenSend: true,
    });

    return Array.from(e.target).map((INPUT) => {
      if (INPUT.type === "checkbox") return INPUT.checked;
      return INPUT.value;
    });
  };

  removeRequired = (oneInputFilled, target) => {
    if (!oneInputFilled && target) {
      Array.from(target).forEach((input) => {
        if (input) input.required = "";
      });
    }
  };

  putValueOnRequestMap = (name, value) => {
    if (value !== null && value !== "") {
      this.request.set(name, value);
    } else {
      this.request.delete(name);
    }
  };

  drawInput = (filter, idx) => {
    if (filter.path && filter.label) {
      return (
        <div className="col-lg" key={idx}>
          <label htmlFor={this.props.name}>{filter.label}</label>
          <Selector
            id={idx}
            path={filter.path}
            text={filter.label}
            placeholder={`Seleccione ${filter.label}`}
            property={filter.dataField}
            isForFilter={true}
            getValueForFilter={this.putValueOnRequestMap}
            value={this.state.filters[filter.dataField] || ""}
          ></Selector>
        </div>
      );
    } else {
      return (
        <div className="col-lg" key={idx}>
          <label htmlFor={this.props.name}>{filter.label}</label>
          {this.createInput(filter, idx)}
        </div>
      );
    }
  };

  createInput = (element, idx) => {
    const { filterValues } = this.state;

    switch (element.inputType) {
      case "number":
        return (
          <InputNumberBasic
            idx={idx}
            element={element}
            handleChange={this.handleChange}
            value={filterValues[element.dataField] || ""}
          />
        );
      case "text":
        return (
          <InputTextBasic
            idx={idx}
            element={element}
            handleChange={this.handleChange}
            value={filterValues[element.dataField] || ""}
          />
        );
      case "checkbox":
        return (
          <InputCheckboxBasic
            idx={idx}
            element={element}
            handleChange={this.handleChange}
            checked={filterValues[element.dataField] || false}
          />
        );
      case "dropdown":
        return (
          <InputDropdownBasic
            idx={idx}
            element={element}
            handleChange={this.handleChange}
            value={filterValues[element.dataField] || ""}
          />
        );
      case "date":
        return (
          <InputDateBasic
            idx={idx}
            element={element}
            handleChange={this.handleChange}
            value={filterValues[element.dataField] || ""}
          />
        );
      default:
        return "No se encontró el filtro adecuado.";
    }
  };

  drawErrorMessage = () => this.errorMessage || "Debe completar los campos";

  render() {
    const buttonStyle = {
      height: "2.8rem",
      marginTop: "2rem",
    };

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="card">
          {this.state.showMessage && (
            <div className="alert alert-danger" role="alert">
              {this.drawErrorMessage()}
            </div>
          )}
          <div className="card-body row">
            {this.filters.map((filter, idx) => this.drawInput(filter, idx))}

            <button
              type="submit"
              className="col-lg-auto btn btn-outline-primary ml-3 mr-3"
              style={buttonStyle}
              onClick={() => this.setState({ exportPushed: false })}
            >
              <i className="mdi mdi-magnify mr-1"></i>
              Buscar
            </button>

            {this.hasDownload &&
              this.userProfile.hasInspectorReportPermisson() && (
                <div className="mb-5 pb-4">
                  <button
                    type="submit"
                    className="col-lg-auto btn btn-outline-primary ml-3 mr-3"
                    style={buttonStyle}
                    onClick={() => this.setState({ exportPushed: true })}
                  >
                    <i className="fa fa-download"></i>
                    Exportar
                  </button>
                </div>
              )}

            {!this.errorMessage && this.state.hasBeenSend && (
              <button
                className="btn btn-secondary "
                type="button"
                style={buttonStyle}
              >
                <Link to={"/verificadores"}>
                  <i className=""></i>
                  Limpiar filtro
                </Link>
              </button>
            )}

            {this.props.children}
          </div>
        </div>
      </form>
    );
  }
}
export default Filter;
