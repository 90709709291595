// import { CallMergeTwoTone } from '@material-ui/icons'
import React from "react";
import Info from "../Info.jsx";
import Moment from "moment";
import UserProfile from "../../app/shared/UserProfile";
import Error404 from "../../app/user-pages/Error404";
// import BackButton from "../../app/basic-ui/BackButton.js";

class InspectionView extends Info {
  constructor(props) {
    super(props);
    this.id = props.id ? props.id : props.param.match.params.id;
    this.path = process.env.REACT_APP_INSPECTION;
    this.userProfile = UserProfile.getInstance();
    this.hasRenderPermission = this.userProfile.hasInspectionShowPermisson();

    this.state = {
      id: props.id ? props.id : props.param.match.params.id,
      entity: {
        inspector: {},
        cardInfo: {},
        tripData1: {},
        tripData2: {},
        tripData3: {},
        validationReason: {},
      },
    };
  }

  /*componentDidUpdate = (prevProps, prevState) => {
    if(prevProps !== this.props)
      this.setState
  }*/

  renderHeader(inspection) {
    return (
      <div className="col-xl-12 col-lg-6 col-sm-6 grid-margin-0 grid-margin-xl stretch-card">
        <div
          className={`card card-revenue-${
            inspection.inspectionResult ? "ok" : "error"
          }`}
        >
          <div className="card-body">
            <div className="d-flex w-100 h-100 justify-content-between align-items-center">
              <div className="mr-auto">
                <p className="highlight-text text-white">
                  {" "}
                  <i className="fa fa-info-circle"></i> Validacion{" "}
                </p>
              </div>
              <div className="ml-auto mt-2 mt-xl-0">
                <h1>
                  <strong>
                    <i className="fa fa-angle-double-right"></i> {inspection.id}{" "}
                  </strong>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInspector(inspector) {
    if (!inspector) return;
    return (
      <>
        {this.renderItem("Inspector", inspector.fullname)}
        {this.renderItem("DNI Inspector", inspector.dni)}
      </>
    );
  }

  renderCardInfo(cardInfo) {
    return (
      <>{/* {this.renderItem('Número de Tarjeta', cardInfo.cardNumber)} */}</>
    );
  }

  formatDate(date) {
    let dateParsed = "";

    if (date) {
      date = date.replace("T", " ").slice(0, date.indexOf("."));
      dateParsed = Moment(date).format("DD-MM-YY HH:mm");
    }
    return dateParsed == null ? "" : dateParsed;
  }

  renderInspectionInfo(inspection) {
    if (!inspection) return;
    return (
      <>
        {this.renderCardInfo(inspection.cardInfo)}
        {this.renderItem(
          "Fecha inspección",
          this.formatDate(inspection.inspectionDate)
        )}
        {this.renderItem("Inspección válida?", inspection.result)}
        {inspection.validationReason &&
          (inspection.validationReason.id === 5 ||
            inspection.validationReason.id === 0) &&
          this.renderItem("Detalle motivo", inspection.comment)}
      </>
    );
  }

  renderValue = (value) =>
    !value || value.lenght === 0 ? "" : `$ ${(value / 100).toFixed(2)}`;

  isHistoryVersion1(tripData) {
    return tripData.section === -1 && tripData.direction === -1 ? true : false;
  }

  renderTripData(tripData) {
    tripData = tripData == null ? {} : tripData;

    return (
      <>
        {this.renderItem("Línea", tripData.line)}
        {this.renderItem(
          "Interno",
          this.isHistoryVersion1(tripData) ? "N/A" : tripData.busNumber
        )}
        {this.renderItem(
          "Sección",
          this.isHistoryVersion1(tripData) ? "N/A" : tripData.section
        )}
        {this.renderItem(
          "Sentido",
          this.isHistoryVersion1(tripData)
            ? "N/A"
            : tripData.direction === 1
            ? "Vuelta"
            : tripData.direction === 0
            ? "Ida"
            : tripData.direction
        )}
        {this.renderItem("Fecha", this.formatDate(tripData.tripDate))}
        {this.renderItem("Valor", this.renderValue(tripData.value))}
        {tripData.socialAttribute &&
          this.renderItem("Atributo", tripData.socialAttribute)}
      </>
    );
  }

  render = () => {
    const {
      entity,
      entity: { inspector, tripData1, tripData2, tripData3 },
    } = this.state;

    if (!this.hasRenderPermission) return <Error404 />;
    else
      return (
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-sm-12 grid-margin stretch-card">
            <div className="row flex-grow">
              {this.renderHeader(entity)}

              <div className="col-xl-12 col-lg-6 col-sm-6 stretch-card">
                <div className="card card-revenue-table mt-4 mt-sm-0 mt-xl-4">
                  <div className="card-body">
                    {this.renderInspector(inspector)}
                  </div>
                </div>
              </div>

              <div className="col-xl-12 col-lg-6 col-sm-6 stretch-card">
                <div className="card card-revenue-table mt-4 mt-sm-0 mt-xl-4">
                  <div className="card-body">
                    {this.renderInspectionInfo(entity)}
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-sm-4 stretch-card">
                <div className="card card-revenue-table mt-4 mt-sm-0 mt-xl-4">
                  <div className="card-body">
                    {this.renderTripData(tripData1)}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-4 stretch-card">
                <div className="card card-revenue-table mt-4 mt-sm-0 mt-xl-4">
                  <div className="card-body">
                    {this.renderTripData(tripData2)}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-4 stretch-card">
                <div className="card card-revenue-table mt-4 mt-sm-0 mt-xl-4">
                  <div className="card-body">
                    {this.renderTripData(tripData3)}
                  </div>
                </div>
              </div>
              {/* <BackButton /> */}
            </div>
          </div>
        </div>
      );
  };
}

export default InspectionView;
