import Keycloak from "keycloak-js";
import UserProfile from "../app/shared/UserProfile";

const _kc = new Keycloak(window.globalConfig.keycloak);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "login-required",
    })
    .then((authenticated) => {
      if (authenticated) {
        UserProfile.getInstance().setUserInfo(userInfo());
        UserProfile.getInstance().setToken(_kc.token);
        setTimeout(updateToken, 1000 * 60 * 15);
        onAuthenticatedCallback();
      } else {
        console.warn("not authenticated!");
        doLogin();
      }
    });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const updateToken = (successCallback) => {
  // console.log("Refresque token.");
  return _kc
    .updateToken(15)
    .then(() => {
      // successCallback()
      setTimeout(doLogout, 1000 * 60 * 15);
    })
    .catch(doLogin);
};

const userInfo = () => {
  var parsedToken = _kc.tokenParsed;
  var resource = parsedToken.resource_access[_kc.clientId];
  return {
    user: parsedToken.preferred_username,
    name: parsedToken.name,
    email: parsedToken.email,
    roles: resource != null ? resource.roles : null,
    logout: doLogout,
  };
};

const services = {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  updateToken,
};

export default services;
